import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14')
];

export const server_loads = [0];

export const dictionary = {
		"/blogs/[category]": [~5],
		"/blogs/[category]/[articleSlug]": [~6],
		"/checkout/success": [~7,[2]],
		"/exercises": [~8],
		"/exercises/[exercise]": [~9],
		"/join/[...joinPageSlug]": [~10,[3]],
		"/programs": [~11],
		"/programs/[programSlug]": [~12],
		"/trainers": [~13],
		"/trainers/[trainerSlug]": [~14],
		"/[...contentPageSlug]": [~4]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';